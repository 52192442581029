import React, { useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage, Field, FormikConfig } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';

import {
    container,
    header,
    title,
    info,
    icon,
    input,
    error,
    loading,
    button,
    fields,
    formField,
    calendarIcon,
} from './halloween-winner-form.module.scss';

import WinnerIcon from '../../assets/images/svg/winner.svg';

import pagesContext from '../../config/pages-context';
import {
    getInitialValues,
    getValidationSchema,
    IHalloweenContestWinnerValues,
} from '../../formik/halloween-contest-form-winner.config';
import { getHalloweenCheckToken, postHalloweenWinner } from '../../api-ssr/halloween-contest';
import useContestTokenCheck from '../../hooks/use-contest-token-check';

import Button from '../atoms/button';
import Captcha from '../atoms/captcha';
import Loader from '../atoms/loader';
import FormInput from '../atoms/form-input';
import Calendar from '../../assets/images/svg/calendar.svg';
import FormField from '../atoms/form-field';

const RECAPTCHA = process.env.RECAPTCHA;

interface IHalloweenWinnerFormProps {
    className?: string;
}

const HalloweenWinnerForm: React.FC<IHalloweenWinnerFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isTokenCheckLoading, setIsTokenCheckLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync, isSuccess } = useMutation(postHalloweenWinner);
    const [initialValues, setInitialvalues] = useState<IHalloweenContestWinnerValues>();
    const [dateType, setDateType] = useState('text');

    const onLoad = (data: IHalloweenContestWinnerValues) => {
        setIsTokenCheckLoading(false);
        setInitialvalues({ ...data });
    };

    const token = useContestTokenCheck(language, getHalloweenCheckToken, redirectPages, onLoad);

    const handleSubmit: FormikConfig<IHalloweenContestWinnerValues>['onSubmit'] = async (
        values
    ) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
        } catch (error: any) {
            const errorMessage = error?.messages?.map((message: any) => message.content).join(' ');
            alert(errorMessage || error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const resultsUrl = getPathFromSlug(pagesContext.halloweenThankYou.slug, language);
            navigate(`${resultsUrl}?from=winner`);
        }
    }, [isSuccess]);

    if (isTokenCheckLoading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={{
                ...getInitialValues(token),
                ...initialValues,
                phone:
                    initialValues && initialValues.phone
                        ? initialValues.phone.replace('48.', '')
                        : '',
            }}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
            enableReinitialize={true}
        >
            {({ errors, touched }) => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <div className={header}>
                        <WinnerIcon className={icon} />
                        <h1 className={title}>{t('halloween.winner.form.title')}</h1>
                        <p className={info}>{t('halloween.winner.form.info')}</p>
                    </div>
                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('halloween.winner.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('halloween.winner.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <FormField
                            id={'birthday'}
                            hintText={errors.birthday && touched.birthday ? errors.birthday : null}
                            errorStyle={!!(errors.birthday && touched.birthday)}
                            className={formField}
                        >
                            <>
                                <FormInput
                                    id={'birthday'}
                                    name={'birthday'}
                                    placeholder={t('halloween.form.birthday.placeholder')}
                                    errorStyle={!!(errors.birthday && touched.birthday)}
                                    className={input}
                                    onClick={() => {
                                        setDateType('date');
                                    }}
                                    type={dateType}
                                />
                                <Calendar className={calendarIcon} />
                            </>
                        </FormField>
                        <div>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('halloween.winner.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                type="tel"
                                name="phone"
                                placeholder={t('halloween.winner.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('halloween.winner.form.street.placeholder')}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="houseNumber"
                                placeholder={t('halloween.winner.form.houseNumber.placeholder')}
                            />
                            <ErrorMessage name="houseNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t('halloween.winner.form.apartmentNumber.placeholder')}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('halloween.winner.form.zipCode.placeholder')}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="city"
                                placeholder={t('halloween.winner.form.city.placeholder')}
                            />
                            <ErrorMessage name="city" className={error} component="p" />
                        </div>
                    </div>
                    <Button type="submit" className={button}>
                        {t('halloween.winner.form.submit')}
                    </Button>
                    {RECAPTCHA && <Captcha siteKey={RECAPTCHA} />}
                </Form>
            )}
        </Formik>
    );
};

const redirectPages = {
    default: pagesContext.halloween.slug,
    expired: pagesContext.halloweenExpired.slug,
};

export default HalloweenWinnerForm;
