import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IHalloweenContestValues } from '../formik/halloween-contest-form.config';
import { IHalloweenContestWinnerValues } from '../formik/halloween-contest-form-winner.config';

export const halloweenFormMutation = (data: IHalloweenContestValues) => {
    return axios.post(prependApiUrl('/halloween-zabka/create'), {
        ...data,
    });
};

export const postHalloweenWinner = (createForm: IHalloweenContestWinnerValues) => {
    return axios.post(prependApiUrl('/halloween-zabka/addresses'), {
        ...createForm,
    });
};

export const getHalloweenCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/halloween-zabka/check/${token}`), {});
};
